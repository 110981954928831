<template>
  <el-dialog title="上传进度" :visible.sync="isShowFileList" width="1200px" :before-close="handleClose"
             center>
    <el-table :data="fwVideoList" border style="width: 100%" :height="tableHeight" size="mini"
              header-row-class-name="table_header" empty-text="暂无上传内容" row-key="id">
      <el-table-column type="index" label="序号" width="80" align="center">
      </el-table-column>
      <el-table-column prop="fileName" label="文件名称" width="200" align="center">
      </el-table-column>
      <el-table-column prop="videoType" label="文件格式" width="120" align="center">
      </el-table-column>
      <el-table-column prop="fileSize" label="文件大小" width="120" align="center">
        <template v-slot="{row}">
          <span>{{setfileSize(row.fileSize)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="视频时长" width="100" align="center">
        <template v-slot="{row}">
          <span>{{initVideoDuration(row.videoDuration)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="percent" label="上传进度" align="center">
        <template v-slot="{row}">
          <el-progress :percentage="Math.floor(row.percent)"
                       :status="uploadType[row.uploadType]"></el-progress>
        </template>
      </el-table-column>
      <el-table-column prop="uploadType" label="上传状态" width="100" align="center">
        <template v-slot="{row}">
          <span>{{row.uploadType}}</span>
          <el-popover v-if="row.errMsg" placement="left-end" title="失败原因" width="200"
                      trigger="hover" :content="row.errMsg">
            <i class="el-icon-question" slot="reference"></i>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="creatTime" label="上传时间" width="120" align="center">
        <template v-slot="{row}">
          <span>{{initTime(row.creatTime)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="creatTime" label="操作" width="80" align="center">
        <template v-slot="{row}">
          <el-tooltip class="item" effect="dark" :content="deleteTooltipContent(row.uploadType)"
                      placement="top">
            <i class="delete_icon el-icon-document-delete" @click="onDeleteIconBtn(row)"
               :class="{'disable':['上传成功','正在上传'].includes(row.uploadType)}"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>
<script>
import moment from 'moment'
import 'moment/locale/zh-cn'
export default {
  props: {
    isShowFileList: {
      type: Boolean,
      default: false
    },
    fwVideoList: {
      type: Array,
      default: () => []
    },
    // 初始化文件大小
    setfileSize: {
      type: Function,
      default: () => { }
    },
    // 初始化视频时长
    initVideoDuration: {
      type: Function,
      default: () => { }
    }
  },
  data () {
    return {
      tableHeight: window.innerHeight - 220,
      uploadType: {
        上传成功: 'success',
        上传失败: 'exception'
      }
    }
  },
  methods: {
    // 删除图标按钮
    onDeleteIconBtn (row) {
      if (row.uploadType === '上传成功' || row.uploadType === '正在上传') return
      this.$emit('onDeleteFileItem', row)
    },
    // 处理提示
    deleteTooltipContent (type) {
      const typeMap = {
        上传失败: '删除当前上传失败记录',
        正在上传: '上传中，不可取消上传',
        上传成功: '已上传成功，不可删除',
        等待上传: '取消上传'
      }
      return typeMap[type]
    },
    // 处理日期
    initTime (time) {
      return moment(time).fromNow()
    },
    // // 处理文件单位
    // setfileSize (num) {
    //   if (num / 1024 < 1024) {
    //     return (num / 1024).toFixed(2) + 'KB'
    //   } else if (num / 1024 / 1024 < 1024) {
    //     return (num / 1024 / 1024).toFixed(2) + 'MB'
    //   } else {
    //     return (num / 1024 / 1024 / 1024).toFixed(2) + 'GB'
    //   }
    // },
    // 关闭弹窗
    handleClose () {
      this.$emit('handleClose')
      this.$emit('update:isShowFileList', false)
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.el-dialog {
  margin-top: 55px !important;
  min-width: 1000px;
}
/deep/ .table_header {
  th {
    background-color: #e5eaf5;
  }
}
.delete_icon {
  position: relative;
  top: 2px;
  font-size: 18px;
  color: #409eff;
}
.disable {
  color: rgba(0, 0, 0, 0.3);
  cursor: not-allowed;
}
</style>
