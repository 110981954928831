var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":"上传进度","visible":_vm.isShowFileList,"width":"1200px","before-close":_vm.handleClose,"center":""},on:{"update:visible":function($event){_vm.isShowFileList=$event}}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.fwVideoList,"border":"","height":_vm.tableHeight,"size":"mini","header-row-class-name":"table_header","empty-text":"暂无上传内容","row-key":"id"}},[_c('el-table-column',{attrs:{"type":"index","label":"序号","width":"80","align":"center"}}),_c('el-table-column',{attrs:{"prop":"fileName","label":"文件名称","width":"200","align":"center"}}),_c('el-table-column',{attrs:{"prop":"videoType","label":"文件格式","width":"120","align":"center"}}),_c('el-table-column',{attrs:{"prop":"fileSize","label":"文件大小","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.setfileSize(row.fileSize)))])]}}])}),_c('el-table-column',{attrs:{"label":"视频时长","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.initVideoDuration(row.videoDuration)))])]}}])}),_c('el-table-column',{attrs:{"prop":"percent","label":"上传进度","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-progress',{attrs:{"percentage":Math.floor(row.percent),"status":_vm.uploadType[row.uploadType]}})]}}])}),_c('el-table-column',{attrs:{"prop":"uploadType","label":"上传状态","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.uploadType))]),(row.errMsg)?_c('el-popover',{attrs:{"placement":"left-end","title":"失败原因","width":"200","trigger":"hover","content":row.errMsg}},[_c('i',{staticClass:"el-icon-question",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"creatTime","label":"上传时间","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.initTime(row.creatTime)))])]}}])}),_c('el-table-column',{attrs:{"prop":"creatTime","label":"操作","width":"80","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.deleteTooltipContent(row.uploadType),"placement":"top"}},[_c('i',{staticClass:"delete_icon el-icon-document-delete",class:{'disable':['上传成功','正在上传'].includes(row.uploadType)},on:{"click":function($event){return _vm.onDeleteIconBtn(row)}}})])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }