<template>
  <div class="upload_videobox">

    <div class="text_top_box row mb10">
      <el-alert v-if="warmingType.type" :title="warmingType.title" :type="warmingType.type"
                :closable="false" show-icon></el-alert>
      <!-- <el-alert v-if="warmingType === 'success'" title="所有视频均已上传成功" type="success" :closable="false"></el-alert>
      <el-alert v-if="stack.length !== 0 && stack.filter(item=>item==='上传失败')" title="有视频上传失败请查看" type="error" :closable="false"></el-alert> -->
    </div>

    <el-card class="upload_video_content" v-if="tableData.length">
      <SubmitUserInfoDialog ref="submitUserInfoDialog" @updateList="getLw2List" />
      <div class="table_box">
        <el-table :data="tableData" style="width: 100%" size="mini" border stripe :height="height"
                  header-row-class-name="table_header" row-key="id" @expand-change="expandChange">
          <el-table-column type="expand" v-if="true">
            <template v-slot="{row}">
              <div class="childrenTable_box">
                <childrenTable :tableData="row.childrenTableData" :initTime="initTime"
                               :setfileSize="setfileSize" :initVideoDuration="initVideoDuration"
                               :onSeeVideoDetails="onSeeVideoDetails" :delFile="beforeDelFile"
                               :fwInfo="row" />
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="id" label="任务编号" width="80" align="center">
          </el-table-column>
          <el-table-column prop="name" label="任务类型" width="150" align="center">
            <template v-slot="{row}">
              <span>{{lwTypeList[row.name-1]}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="created_time" label="创建日期" width="100" align="center">
            <template v-slot="{row}">
              <span>{{$initTime(row.created_time)}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="txsl" label="任务数量" width="80" align="center">
          </el-table-column>
          <el-table-column prop="txdj" label="任务单价" width="80" align="center">
          </el-table-column>
          <el-table-column prop="scsl" label="已上传数量" width="100" align="center">
          </el-table-column>
          <el-table-column prop="created_time" label="操作日期" width="140" align="center">
            <template v-slot="{row}">
              <span
                    v-if="row.verified">{{$initTime(row.modified_time)}}{{setTimeType(row.verified)}}</span>
              <span v-else>---</span>
            </template>
          </el-table-column>
          <!-- isAudit -->
          <el-table-column label="任务状态" align="center" width="100">
            <template v-slot="{row}">
              <div>
                <el-tag v-if="row.verified===0" type="primary" size="mini">待确认</el-tag>
                <el-tag v-if="row.verified===1" type="warning" size="mini">未提交</el-tag>
                <el-tag v-if="row.verified===2" type="success" size="mini">已提交</el-tag>
                <el-tag v-if="row.verified===3" type="info" size="mini">已审核</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" min-width="200" align="center">
            <template v-slot="{row}">
              <!--  -->

              <el-button type="text" size="mini" class="table_right_btn"
                         @click="setUploadVideoFwId(row.id)"
                         :disabled="row.verified!==1 || row.scsl === Number(row.txsl)">
                <!-- video/mp4, video/quicktime  -->
                <el-upload class="upload-demo" :limit="Number(row.txsl)" action="" accept="*"
                           :disabled="row.verified!==1 || row.scsl === Number(row.txsl)"
                           :show-file-list="false" :on-exceed="onExceed" :http-request="submitVideo"
                           :before-upload="beforeVideoUpload">
                  <div class="upladvideo_btn"
                       :class="{'is-disabled':row.verified!==1|| row.scsl === Number(row.txsl)}">
                    <span>上传文件</span>
                  </div>
                </el-upload>
              </el-button>
              <!--  -->
              <el-button @click="openUploadFileListDialog(row.id)" type="text" size="mini"
                         class="table_right_btn" :disabled="row.verified!==1">
                <span class="open_uploadList_btn"
                      :class="{'is-disabled':row.verified!==1}">上传进度</span>
              </el-button>
              <el-button @click="onSubmitFwData(row)"
                         :disabled="row.verified!==1 || row.scsl !== Number(row.txsl)" type="text"
                         size="mini" class="table_right_btn">
                <span class="submit_btn"
                      :class="{'is-disabled':row.verified!==1 || row.scsl !== Number(row.txsl)} ">提交任务</span>
              </el-button>
              <img v-if="fileList[row.id] && fileList[row.id].length > 0"
                   src="@/assets/img/fwVideo/upload_icon.gif" class="upload_icon" alt="">
              <!-- <i v-if="fileList[row.id] && fileList[row.id].length > 0" class="el-icon-upload2"></i> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination :page-size="pageObj.page_size" :pager-count="11"
                     layout="sizes, prev, pager, next" :total="count" :page-sizes="[10,20,30]"
                     @size-change="sizeChange" @current-change="pageNumChange">
      </el-pagination>
    </el-card>
    <!-- 视频上传进度弹框 -->
    <uploadFileList :isShowFileList.sync="isShowFileList" :initVideoDuration="initVideoDuration"
                    :setfileSize="setfileSize" :fwVideoList="fwVideoList"
                    @handleClose="onCloseUploadFileListDialog"
                    @onDeleteFileItem="onDeleteFileItem" />

    <!-- 视频播放弹框 -->
    <uploadVideoDetails ref="videoDetailsDialog" :isVideoDetails.sync="isVideoDetails" />

    <!-- 追加视频信息弹框 -->
    <addVideoInfo :isOpenAddVideoInfo.sync="isOpenAddVideoInfo" />
    <!-- 密钥输入弹框 -->
    <MyDialog ref="passwordDialog" :isShow.sync="showPassword" width="500px" height="260px"
              title="请输入个人密钥" :maskClose="false">
      <div class="password_content">
        <el-input v-model="password" placeholder="请输入个人密钥"></el-input>
        <div class="btn_box tac">
          <el-button size="small" @click="closePassword">返回首页</el-button>
          <el-button type="primary" size="small" @click="checkPassword">校验密钥</el-button>
        </div>
      </div>

    </MyDialog>
  </div>
</template>
<script>
import uploadFileList from './components/uploadFileList.vue'
import uploadVideoDetails from './components/uploadVideoDetails.vue'
import addVideoInfo from './components/addVideoInfo.vue'
import childrenTable from './components/childrenTable.vue'
import SubmitUserInfoDialog from './components/submitUser.vue'
import MyDialog from '@/components/myDialog/newIndex.vue'

import { uploadVideo, getVideoList, editLabour, fw2DataList, delFwFileInQn, delFwFile } from '@/api/lw.js'
import * as qiniu from 'qiniu-js'
import moment from 'moment'
const path = require('path')
export default {
  components: {
    uploadFileList,
    uploadVideoDetails,
    addVideoInfo,
    childrenTable,
    SubmitUserInfoDialog,
    MyDialog
  },
  data () {
    return {
      password: '',
      fwGroupCount: 0,
      fileList: {}, // 总上传任务队列
      isShowFileList: false, // 上传进度弹框状态位
      pageObj: {
        page: 1,
        pageSize: 10
      },
      count: 0, // 服务总数
      height: window.innerHeight - 200,
      tableData: [],
      lwTypeList: ['医生KOL线上讲座', '医学科普推广', '国内外学术会议', '手术直播', '远程教学查房', '文献校对', '线上会诊', '空中MDT', '线上调研', '患者招募', '学科建设', '病例收集'],
      isVideoDetails: false, // 视频详情弹框状态位
      isOpenAddVideoInfo: false, // 追加视频详情弹框
      fwId: null, // 上传的视频id
      fwVideoList: [], // 预览上传进度队列
      isSuccess: false, // 是否都上传完毕
      showPassword: false
    }
  },
  methods: {
    // 删除文件
    async delFile (info, row) {
      try {
        await delFwFile({
          id: info.id
        })
        this.$message.success('删除成功')
        this.expandChange(row, [row])
        delFwFileInQn(info.video_url)
      } catch (error) {
        this.$message.error(error || '删除成功')
      }
    },
    // 删除文件询问
    beforeDelFile (info, row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '系统提示', {
        confirmButtonText: '确认删除',
        cancelButtonText: '再想想',
        type: 'warning'
      }).then(() => {
        this.delFile(info, row)
      }).catch(() => {

      })
    },
    closePassword () {
      this.$refs.passwordDialog.closeDialog()
      this.password = ''
      this.$router.push('/')
    },
    checkPassword () {
      if (!this.password || /\s/g.test(this.password)) return this.$message.warning('请输入有效内容')
      this.pageObj.page = 1
      this.getLw2List()
    },
    // 打开视频详情弹框
    onSeeVideoDetails (row) {
      if (!row.video_duration) {
        return window.open(row.video_url)
      }
      this.isVideoDetails = true
      this.$refs.videoDetailsDialog.openDialog(row.video_url)
    },
    // 处理操做日期类型
    setTimeType (type) {
      const titleMap = {
        1: '确认',
        2: '提交',
        3: '审核'
      }
      return titleMap[type]
    },
    // 更改本地字段减少服务请求
    updataLocalTabableData (id, fn) {
      const row = this.tableData.find(item => item.id === id)
      if (row && fn) fn(row)
    },
    // 提交审核
    async onSubmitFwData (row) {
      const res = await editLabour(row.id, { verified: 2 })
      if (res.code === 200) {
        this.updataLocalTabableData(row.id, (data) => {
          data.verified = 2
        })
      }
    },
    // 处理日期
    initTime (time) {
      return moment(Number(time)).format('yyyy-MM-DD HH:mm')
    },
    // 处理文件大小单位
    setfileSize (size) {
      const num = Number(size)
      if (num / 1024 < 1024) {
        return (num / 1024).toFixed(2) + 'KB'
      } else if (num / 1024 / 1024 < 1024) {
        return (num / 1024 / 1024).toFixed(2) + 'MB'
      } else {
        return (num / 1024 / 1024 / 1024).toFixed(2) + 'GB'
      }
    },
    // 展开行回调
    async expandChange (row, expandedRows) {
      if (expandedRows.length === 0) return
      const res = await getVideoList({ laowu_id: row.id })
      if (res.code === 200) {
        row.childrenTableData = res.data.unfinish_result
      }
    },
    // 关闭进度队列弹框
    onCloseUploadFileListDialog () {
      this.fwVideoList = []
    },
    // 打开进度队列弹框
    openUploadFileListDialog (fwID) {
      this.isShowFileList = true
      this.fwVideoList = this.fileList[fwID] || []
    },
    // 超出上传个数回调
    onExceed () {
      this.$message.warning('该任务上传视频数量已足够,如需上传新的视频请删除正在上传的视频')
    },
    // 设置上传视频的服务id
    setUploadVideoFwId (id) {
      this.fwId = id
    },
    // 获取用户服务视频列表
    async getLw2List () {
      const { data } = await fw2DataList({
        password: this.password,
        ...this.pageObj
      })
      if (data === true) {
        this.showPassword = true
      } else {
        this.tableData = data.list.map(item => ({ ...item, childrenTableData: [] }))
        this.count = data.count
        if (this.password && !this.count) {
          this.$message.warning('密钥验证失败')
          this.password = ''
          return
        }
        if (this.showPassword) {
          this.$refs.passwordDialog.closeDialog()
        }
      }
    },
    // 上传进度弹框暴漏删除事件
    onDeleteFileItem (row) {
      // if (row.uploadType !== '等待上传') {
      //   this.fileList[row.fwId].subscription.unsubscribe()
      // }
      if (row.uploadType === '正在上传') return this.$message.warning('该视频正在上传不可删除')
      // this.$delete(this.fileList, row.creatTime)
      const videoList = this.fileList[row.fwId]
      const i = videoList.findIndex(item => item.id === row.id)
      if (videoList.length === 1) {
        this.fwVideoList = []
        this.$delete(this.fileList, row.fwId)
      } else {
        videoList.splice(i, 1)
      }
      this.$message.success(row.uploadType === '等待上传' ? '已取消上传' : '已删除当前上传失败记录')
    },
    // 开启追加信息弹框
    onOpenAddInfo () {
      this.isOpenAddVideoInfo = true
    },
    // 页码改变
    pageNumChange (pageNum) {
      this.pageObj.page = pageNum
      this.getLw2List()
    },
    // 条数改变
    sizeChange (pageSize) {
      this.pageObj.page_size = pageSize
      this.getLw2List()
    },
    // 上传文件验证
    beforeVideoUpload (file) {
      const isVideo = true // ['video/mp4', 'video/quicktime'].includes(file.type)
      const isSize = (file.size / 1024 / 1024 / 1024) < 1
      if (!isVideo) {
        this.$message.warning('请上传指定格式的视频文件')
      }
      if (!isSize) {
        this.$message.warning('请上传1GB以内的文件')
      }
      return isVideo && isSize
    },
    // 提交上传结果
    async submitVideoInfo (data) {
      const res = await uploadVideo('POST', { data })
      if (res.code === 200) {
        this.updataLocalTabableData(data.laowu_id, (data) => {
          data.scsl += 1
        })
        // this.getLwVideoList()
      }
    },
    // 上传七牛
    async setFileUpload () {
      const that = this
      const arr = Object.values(this.fileList).flat(Infinity)
      if (arr.filter((item) => item.uploadType === '正在上传').length >= 2) return
      const waitUploadFileObj = arr.find((item) => item.uploadType === '等待上传')
      if (!waitUploadFileObj) {
        this.isSuccess = true
        return
      }
      waitUploadFileObj.uploadType = '正在上传'
      const config = {
        useCdnDomain: true,
        region: qiniu.region.z1
      }
      const putExtra = {
        fname: waitUploadFileObj.fileName,
        mimeType: waitUploadFileObj.fileObj.type
      }
      const observer = {
        next (res) {
          waitUploadFileObj.percent = res.total.percent
        },
        error (err) {
          console.log(err)
          waitUploadFileObj.uploadType = '上传失败'
          waitUploadFileObj.errMsg = err.message
          console.log('失败')
          that.setFileUpload()
        },
        complete (res) {
          waitUploadFileObj.uploadType = '上传成功'
          that.setFileUpload()
          that.submitVideoInfo({
            video_duration: waitUploadFileObj.videoDuration,
            video_type: waitUploadFileObj.videoType,
            laowu_id: waitUploadFileObj.fwId,
            file_size: waitUploadFileObj.fileSize,
            file_name: waitUploadFileObj.fileName,
            video_label: '',
            upload_time: waitUploadFileObj.creatTime,
            video_url: 'https://misc.infox-med.com/' + res.key
          })
          const videoList = that.fileList[waitUploadFileObj.fwId]
          const completes = that.fileList[waitUploadFileObj.fwId].filter(item => item.uploadType === '上传成功')
          if (completes.length === videoList.length) {
            that.fwVideoList = []
            that.$delete(that.fileList, waitUploadFileObj.fwId)
          }
        }
      }
      let res = {}
      try {
        res = await uploadVideo('GET', {
          params: { v_id: waitUploadFileObj.id, video_type: waitUploadFileObj.videoType }
        })
        if (res.code !== 200) return this.$message.warning('上传密钥获取失败')
      } catch (error) {
        return this.$message.warning('上传密钥获取失败')
      }
      waitUploadFileObj.observable = qiniu.upload(waitUploadFileObj.fileObj, res.key, res.token, putExtra, config)
      waitUploadFileObj.subscription = waitUploadFileObj.observable.subscribe(observer)
    },
    // 处理视频时长
    initVideoDuration (duration) {
      if (!duration) return '-'
      const filling = num => num < 10 ? '0' + num : num
      const h = Math.floor(duration / 3600)
      const m = Math.floor((duration - h * 3600) / 60)
      const s = Math.floor((duration - h * 3600 - m * 60))
      return filling(h) + ':' + filling(m) + ':' + filling(s)
    },
    // 上传视频
    async submitVideo (files) {
      const url = URL.createObjectURL(files.file)
      console.log(files)
      const p = new Promise((resolve) => {
        if (['video/mp4', 'video/quicktime'].includes(files.file.type)) {
          const filelement = new Audio(url)
          filelement.addEventListener('loadedmetadata', function (_event) {
            resolve(filelement.duration)
          })
        } else {
          resolve('')
        }
      })
      p.then(duration => {
        const creatTime = new Date().getTime()
        const item = {
          fileName: files.file.name, // 文件名称
          creatTime: creatTime, // 上传时间
          uploadType: '等待上传', // 上传状态
          fileSize: files.file.size, // 文件大小
          percent: 0, // 上传进度
          errMsg: '', // 错误信息
          subscription: {}, // 七牛订阅对象
          observable: {}, // 七牛上传实例
          fileObj: files.file, // 文件对象
          id: this.fwId + '_' + creatTime + '_' + files.file.size,
          fwId: this.fwId,
          videoType: path.extname(files.file.name).slice(1),
          videoDuration: duration
        }
        const arr = this.fileList[this.fwId] || []
        arr.push(item)
        this.$set(this.fileList, this.fwId, arr)
        this.fwId = null
        this.setFileUpload()
      })
    }
  },
  computed: {
    warmingType () {
      const warningObjMap = {
        error: {
          type: 'error',
          title: '有文件上传失败请注意查看'
        },
        success: {
          type: 'success',
          title: '所有文件均已上传成功'
        },
        waining: {
          type: 'warning',
          title: '文件上传中不可刷新或关闭网页'
        }
      }
      const stack = Object.values(this.fileList).flat(Infinity)
      const errorList = stack.filter(item => item.uploadType === '上传失败')
      let key = ''
      if (stack.length !== 0 && errorList.length !== 0) {
        key = 'error'
      } else if (stack.length === 0 && this.isSuccess) {
        key = 'success'
      } else if (stack.length !== 0) {
        key = 'waining'
      }
      return warningObjMap[key] || {}
    }
  },
  activated () {
    if (this.fwGroupCount === 1) {
      this.getLw2List()
    }
    if (this.fwGroupCount > 1) {
      this.showPassword = true
    }
  },
  mounted () {
    this.fwGroupCount = +this.$route.query.count
  }
}
</script>
<style lang="less" scoped>
.upload_videobox {
  width: 100%;
  // min-height: calc(100% - 52px);
  min-height: calc(100vh - 52px);
  padding: 10px 0;
  background-color: #f5f7fb;
  .text_top_box {
    // width: 1200px;
    // margin: 0 auto;
  }
  .upload_video_content {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    /deep/ .el-table__expanded-cell {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
    }
    .childrenTable_box {
      // width: calc(100% - 200px);
      width: 100%;
    }
    /deep/.el-table__fixed-right-patch {
      background-color: #e5eaf5;
    }
    /deep/.expanded {
      background-color: #e7f5de;
      td {
        background-color: #e7f5de !important;
      }
      .el-table__cell {
        background-color: #e7f5de;
      }
    }
    /deep/ .table_header {
      th {
        background-color: #e5eaf5;
      }
    }
    .table_right_btn {
      margin-right: 10px;
      .upladvideo_btn {
        color: #409eff;
      }
      .open_uploadList_btn {
        color: #67c23a;
      }
      .submit_btn {
        color: #e6a23c;
      }
      .is-disabled {
        cursor: not-allowed;
        color: #c0c4cc;
      }
    }
  }
  /deep/ .el-card__body {
    padding: 30px 20px 10px;
  }
  /deep/.el-pagination {
    margin-top: 10px;
  }
}
/deep/.el-table__expanded-cell {
  padding: 20px;
}
.upload_icon {
  width: 18px;
}

.password_content {
  padding: 50px 60px;
  .btn_box {
    margin-top: 30px;
  }
}
/deep/.close_icon {
  display: none;
}
</style>
