var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"size":"mini"}},[_c('el-table-column',{attrs:{"prop":"","label":"","width":"50","align":"center","resizable":false}},[[_c('span')]],2),_c('el-table-column',{attrs:{"type":"index","label":"序号","width":"80","align":"center"}}),_c('el-table-column',{attrs:{"prop":"file_name","label":"文件名称","min-width":"200","align":"center"}}),_c('el-table-column',{attrs:{"prop":"video_type","label":"文件格式","width":"120","align":"center"}}),_c('el-table-column',{attrs:{"label":"视频时长","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.initVideoDuration(row.video_duration)))])]}}])}),_c('el-table-column',{attrs:{"label":"文件大小","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.setfileSize(Number(row.file_size))))])]}}])}),_c('el-table-column',{attrs:{"prop":"upload_time","label":"上传时间","width":"200","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.initTime(row.upload_time)))])]}}])}),_c('el-table-column',{attrs:{"label":"","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('TooltipBtn',{staticClass:"mr20",attrs:{"content":"查看文件信息","iconName":"el-icon-view","color":"#2e92fa"},on:{"click":function($event){return _vm.onSeeVideoDetails(row)}}}),_c('TooltipBtn',{attrs:{"content":"删除当前文件","disabled":_vm.fwInfo.verified!==1,"iconName":"el-icon-delete","color":"#fa6064"},on:{"click":function($event){return _vm.delFile(row, _vm.fwInfo)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }