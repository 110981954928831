<template>
  <el-dialog :visible.sync="isVideoDetails" width="50%" :before-close="handleClose" center>
    <div id="videodetailsbox"></div>
  </el-dialog>
</template>
<script>
import Player from 'xgplayer'
export default {
  props: {
    isVideoDetails: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      playerObj: {},
      videoUrl: ''
    }
  },
  methods: {
    // 弹窗打开
    openDialog (url) {
      this.videoUrl = url
      this.$nextTick(() => {
        this.initVideo()
      })
    },
    // 初始化播放器
    initVideo () {
      this.playerObj = new Player({
        id: 'videodetailsbox',
        url: this.videoUrl,
        width: '100%', // 播放器宽度
        height: '450px', // 播放器高度
        volume: 0.6, // 初始音量
        playbackRate: [0.5, 0.75, 1, 1.5, 2], // 倍速
        defaultPlaybackRate: 1, // 默认倍速1
        videoInit: true
      })
    },
    // 关闭弹框
    handleClose () {
      this.$emit('update:isVideoDetails', false)
      this.playerObj.destroy()
    }
  }
}
</script>
<style lang="less" scoped>
#videodetailsbox {
  width: 100%;
  height: 400px;
}
/deep/.el-dialog {
  margin-top: 55px !important;
}
</style>
