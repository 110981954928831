<template>
  <div class="con">
    <span class="openBtn hand" ref="openBtn" @click="openDialog">个人信息</span>
    <MyDialog ref="submitDialog" :isShow.sync="show" :position="position" width="640px"
              height="340px" title="个人信息">
      <div class="content">
        <el-form ref="form" :disabled="isDisable" :model="form" label-width="80px" :rules="rules">
          <el-form-item v-for="map in formMaps" :key="map.key" :label="map.label" :prop="map.key">
            <el-input v-model="form[map.key]"></el-input>
          </el-form-item>
        </el-form>

        <div class="btn_options tac" v-if="!isDisable">
          <el-button size="small" plain @click="close">取消</el-button>
          <el-button size="small" type="primary" plain @click="submitValidate">提交信息</el-button>
        </div>
        <div v-else class="tips">
          暂不支持修改，如需修改请联系管理员
        </div>
      </div>
    </MyDialog>
  </div>
</template>

<script>
import MyDialog from '@/components/myDialog/newIndex.vue'
import { submitFwUserInfo } from '@/api/lw.js'
export default {
  props: {

  },
  components: {
    MyDialog
  },
  data () {
    const checkIdCard = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('身份证号不可为空！'))
      }
      const reg = /^(\d{17}[\d|x|X])$/
      if (reg.test(value)) {
        callback()
      } else {
        callback(new Error('身份证号格式不正确！'))
      }
    }
    return {
      show: false,
      position: {},
      isDisable: false,
      formMaps: [
        {
          key: 'skr',
          label: '收款人'
        }, {
          key: 'skzh',
          label: '收款账号'
        }, {
          key: 'sfzh',
          label: '身份证号'
        }
      ],
      form: {
        skr: '',
        skzh: '',
        sfzh: ''
      },
      rules: {
        skr: [
          { required: true, message: '请输入收款人姓名', trigger: 'blur' }
        ],
        skzh: [
          { required: true, message: '请输入收款账号', trigger: 'blur' }
        ],
        sfzh: [
          { required: true, message: '请输入收款人身份证号', trigger: 'blur' },
          { validator: checkIdCard, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    close () {
      this.$refs.submitDialog.closeDialog()
      this.form = this.$options.data().form
    },
    async submit () {
      const info = this.$parent.$parent.tableData[0]
      if (!info) return this.$message.warning('缺少密钥，提交失败！')
      const password = info.organId + info.groupId
      await submitFwUserInfo({
        password: password,
        ...this.form
      })
      this.$message.success('信息提交成功')
      this.$emit('updateList')
      this.close()
    },
    beferSubmit () {
      this.$confirm('提交后不可修改, 是否继续?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.submit()
      }).catch(() => {
        this.$message('提交已取消')
      })
    },
    submitValidate () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.beferSubmit()
        } else {
          return false
        }
      })
    },
    openDialog () {
      this.show = true
      const tableData = this.$parent.$parent.tableData
      if (tableData.length) {
        this.initForm(tableData[0])
      }
    },
    initForm (info) {
      Object.keys(this.form).forEach(key => {
        this.form[key] = info[key]
      })
      this.isDisable = Object.keys(this.form).every(key => this.form[key])
    }
  },
  computed: {

  },
  mounted () {
    const btnOptions = this.$refs.openBtn.getBoundingClientRect()
    this.position = {
      x: btnOptions.left,
      y: btnOptions.top
    }
  },
  created () {

  }
}
</script>

<style lang="less" scoped>
.openBtn {
  position: absolute;
  top: 10px;
  right: 10px;
}
.content {
  padding: 40px 30px;
}
</style>
