<template>
  <el-table :data="tableData" size="mini" style="width: 100%">
    <el-table-column prop="" label="" width="50" align="center" :resizable="false">
      <template>
        <span></span>
      </template>
    </el-table-column>
    <el-table-column type="index" label="序号" width="80" align="center">
    </el-table-column>
    <el-table-column prop="file_name" label="文件名称" min-width="200" align="center">
    </el-table-column>
    <el-table-column prop="video_type" label="文件格式" width="120" align="center">
    </el-table-column>
    <el-table-column label="视频时长" width="120" align="center">
      <template v-slot="{row}">
        <span>{{initVideoDuration(row.video_duration)}}</span>
      </template>
    </el-table-column>
    <el-table-column label="文件大小" width="120" align="center">
      <template v-slot="{row}">
        <span>{{setfileSize(Number(row.file_size))}}</span>
      </template>
    </el-table-column>
    <el-table-column prop="upload_time" label="上传时间" width="200" align="center">
      <template v-slot="{row}">
        <span>{{initTime(row.upload_time)}}</span>
      </template>
    </el-table-column>
    <el-table-column label="" width="100" align="center">
      <template v-slot="{row}">
        <TooltipBtn content="查看文件信息" iconName="el-icon-view" color="#2e92fa"
                    @click="onSeeVideoDetails(row)" class="mr20" />
        <TooltipBtn content="删除当前文件" :disabled="fwInfo.verified!==1" iconName="el-icon-delete"
                    color="#fa6064" @click="delFile(row, fwInfo)" />
        <!-- <el-button @click="props.onSeeVideoDetails(row)" size="mini" type="primary"
                   icon="el-icon-view" circle></el-button>

        <el-button @click="props.delFile(row)" :disabled="props.fwInfo.verified!==1" type="danger"
                   size="mini" icon="el-icon-delete" circle></el-button> -->
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
import TooltipBtn from './tooltipBtn.vue'
export default {
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    setfileSize: {
      type: Function,
      default: () => { }
    },
    initTime: {
      type: Function,
      default: () => { }
    },
    initVideoDuration: {
      type: Function,
      default: () => { }
    },
    onSeeVideoDetails: {
      type: Function,
      default: (val) => console.log(val)
    },
    delFile: Function,
    fwInfo: Object
  },
  components: {
    TooltipBtn
  }
}
</script>
<style lang="less" scoped>
.el-table {
  /deep/td {
    background-color: #f6fbf3 !important;
  }
  /deep/ th {
    background-color: #f6fbf3;
  }
}
.mr20 {
  margin-right: 20px;
}
</style>
