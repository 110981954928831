<template>
  <el-dialog title="追加视频信息" width="40%" :visible.sync="isOpenAddVideoInfo" :before-close="handleClose" center>
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="视频标题">
        <el-input v-model="form.videoInfo" placeholder="请输入视频标题"></el-input>
      </el-form-item>
      <el-form-item label="作者信息">
        <el-input v-model="form.docAuthor" placeholder="请输入作者信息"></el-input>
      </el-form-item>
      <el-form-item label="视频封面">
        <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false">

          <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="视频介绍">
        <el-input type="textarea" :rows="4" v-model="form.videoText" placeholder="请输入视频介绍"></el-input>
      </el-form-item>
      <el-form-item label="视频分类">
        <el-select v-model="form.tag" placeholder="请选择视频分类">
          <el-option label="内科" value="1"></el-option>
          <el-option label="外科" value="2"></el-option>
          <el-option label="重大疾病" value="3"></el-option>
          <el-option label="其他科室" value="4"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
export default {
  props: {
    isOpenAddVideoInfo: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: {
        date: '',
        name: 'HR',
        docAuthor: '',
        videoImg: '',
        videoInfo: '',
        tag: '',
        videoText: '',
        type: '云加密'
      }
    }
  },
  methods: {
    handleClose () {
      this.$emit('update:isOpenAddVideoInfo', false)
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}
/deep/ .avatar {
  width: 150px;
  height: 150px;
  display: block;
}
.el-form {
  padding: 0 50px;
}
.el-select {
  width: 100%;
}
</style>
