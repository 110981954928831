<template>
  <el-tooltip effect="dark" :content="content" placement="top">
    <slot>
      <i class="icon" @click="iconClick" :class="[iconName, className, {disabled_icon:disabled}]"
         :style="`color:${color};`"></i>
    </slot>
  </el-tooltip>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ''
    },
    iconName: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#4b639f'
    }
  },
  data () {
    return {

    }
  },
  methods: {
    iconClick () {
      if (this.disabled) return
      this.$emit('click')
    }
  },
  created () {

  }
}
</script>

<style lang="less" scoped>
.icon {
  font-size: 18px;
  cursor: pointer;
  font-weight: inherit;
}
.disabled_icon {
  // color: #ccc !important;
  opacity: 0.4;
  cursor: no-drop;
}
</style>
